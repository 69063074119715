import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Login from './components/Login';
import UserIDCard from './components/UserIDCard';
import BusinessDetail from './components/CompanyDetail';
import Search from './components/Search';
import Register from './components/Register';
import MenuBar from './components/MenuBar';
import Profile from './components/Profile';
import Settings from './components/Settings';
import CompanyCategory from './components/CompanyCategory';
import IDCardVerifier from './components/IDCardVerifier';
import CardVerification from './components/CardVerification';
import ChatBot from './components/ChatBot';
import QRScanner from './components/QRScanner';

const checkAuth = () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const userData = localStorage.getItem('user');
    return !!(accessToken && userData && JSON.parse(userData));
  } catch (error) {
    console.error('Error checking auth:', error);
    return false;
  }
};

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuth = checkAuth();

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const isAuth = checkAuth();

  if (isAuth) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const AppContent = ({ darkMode, toggleDarkMode }) => {
  const location = useLocation();
  const isChatbotRoute = location.pathname === '/chatbots';
  const isPublicVerification = location.pathname.startsWith('/verify-card/');
  const isSearchRoute = location.pathname === '/search';

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
      {!isChatbotRoute && !isPublicVerification && !isSearchRoute && (
        <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      )}
      <main className="container mx-auto text-gray-900 dark:text-white ">
        <Routes>
          {/* Rutas Protegidas */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <UserIDCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          {/* Rutas Públicas */}
          <Route path="/chatbots" element={<ChatBot />} />
          
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route path="/verify-card/:id" element={<CardVerification />} />
          <Route path="/business/:id" element={<BusinessDetail />} />
          <Route path="/search" element={<Search />} />
          <Route path="/company-categories/:categoryId" element={<CompanyCategory />} />
          <Route path="/QRscan" element={<QRScanner />} />
        </Routes>
      </main>
      {!isChatbotRoute && !isPublicVerification && <MenuBar />}
    </div>
  );
};

function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    window.history.scrollRestoration = 'manual';
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    if (newDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  return (
    <Router>
      <AppContent darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </Router>
  );
}

export default App;