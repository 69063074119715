import React, { useState, useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { Camera, X, Search, QrCode } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const QRScanner = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [manualId, setManualId] = useState('');
  const [error, setError] = useState('');
  const [qrScanner, setQrScanner] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let scanner = null;

    const initializeScanner = async () => {
      if (isScanning) {
        try {
          await new Promise(resolve => setTimeout(resolve, 100));
          
          scanner = new Html5Qrcode("qr-reader");
          setQrScanner(scanner);

          await scanner.start(
            { facingMode: "environment" },
            {
              fps: 10,
              qrbox: { width: 250, height: 250 },
              aspectRatio: 1,
            },
            (decodedResult) => {
              try {
                const url = new URL(decodedResult);
                const pathParts = url.pathname.split('/');
                const cardId = pathParts[pathParts.length - 1];
                handleStopScanner(); // Stop scanner before navigation
                navigate(`/verify-card/${cardId}`);
              } catch (error) {
                setError('QR inválido. Por favor, intente nuevamente.');
              }
            },
            (errorMessage) => {
              console.log(errorMessage);
            }
          );
        } catch (err) {
          console.error(err);
          setError('Error al acceder a la cámara. Por favor, verifica los permisos.');
          setIsScanning(false);
        }
      }
    };

    initializeScanner();

    return () => {
      if (scanner) {
        scanner.stop().catch(console.error);
      }
    };
  }, [isScanning, navigate]);

  const handleStartScanner = () => {
    setError('');
    setIsScanning(true);
  };

  const handleStopScanner = async () => {
    if (qrScanner) {
      try {
        await qrScanner.stop();
        setQrScanner(null);
      } catch (error) {
        console.error('Error stopping scanner:', error);
      }
    }
    setIsScanning(false);
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const trimmedId = manualId.trim();
    if (trimmedId) {
      navigate(`/verify-card/${trimmedId}`);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#700003] to-[#8a1b1d] p-4 flex items-center justify-center">
      <div className="w-full max-w-md space-y-4">
        <div className="grid grid-cols-1 gap-4">
          <div className="backdrop-blur-xl bg-white/10 rounded-3xl p-6 border border-white/20 shadow-xl">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-white">Escáner QR</h2>
              {isScanning && (
                <button
                  onClick={handleStopScanner}
                  className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
                  aria-label="Detener escáner"
                >
                  <X className="w-6 h-6 text-white" />
                </button>
              )}
            </div>

            <div className="relative">
              {!isScanning ? (
                <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-white/30 rounded-2xl">
                  <QrCode className="w-16 h-16 text-white/60 mb-4" />
                  <button
                    onClick={handleStartScanner}
                    className="px-6 py-3 bg-white/20 hover:bg-white/30 transition-colors rounded-xl text-white font-medium flex items-center gap-2"
                  >
                    <Camera className="w-5 h-5" />
                    Iniciar Escáner
                  </button>
                </div>
              ) : (
                <div className="relative rounded-2xl overflow-hidden bg-black/20 w-full aspect-square">
                  <div 
                    id="qr-reader" 
                    className="w-full h-full"
                    style={{
                      position: 'relative',
                      padding: 0,
                    }}
                  />
                  <div 
                    className="absolute inset-0 w-[250px] h-[250px] border-2 border-white/30"
                    style={{
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1
                    }}
                  />
                </div>
              )}
            </div>

            {error && (
              <div className="mt-4 p-3 bg-red-500/20 border border-red-500/30 rounded-xl text-white text-sm">
                {error}
              </div>
            )}
          </div>

          <div className="backdrop-blur-xl bg-white/10 rounded-3xl p-6 border border-white/20 shadow-xl">
            <h2 className="text-xl font-bold text-white mb-4">Ingreso Manual</h2>
            <form onSubmit={handleManualSubmit} className="space-y-4">
              <div className="relative">
                <input
                  type="text"
                  value={manualId}
                  onChange={(e) => setManualId(e.target.value)}
                  placeholder="Ingrese ID del carnet"
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-xl text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                />
              </div>
              <button
                type="submit"
                className="w-full px-6 py-3 bg-white/20 hover:bg-white/30 transition-colors rounded-xl text-white font-medium flex items-center justify-center gap-2"
              >
                <Search className="w-5 h-5" />
                Verificar
              </button>
            </form>
          </div>
        </div>

        <div className="backdrop-blur-xl bg-white/10 rounded-3xl p-6 border border-white/20 shadow-xl">
          <p className="text-white/80 text-sm text-center">
            Escanee el código QR del carnet o ingrese manualmente el ID para verificar su validez
          </p>
        </div>
      </div>
    </div>
  );
};

export default QRScanner;