import React, { useState, useEffect } from 'react';
import { Clock, Phone, MapPin, Facebook, Instagram, Twitter, Globe, Flame } from 'lucide-react';
import { useParams } from 'react-router-dom';

const SocialLink = ({ url, icon: Icon, platform }) => {
  if (!url) return null;
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="p-2 bg-white/10 rounded-full hover:bg-red-500/20 transition-all duration-300 group"
    >
      <Icon className="w-5 h-5 text-gray-300 group-hover:text-red-400 group-hover:scale-110 transition-transform" />
    </a>
  );
};

const WhatsAppButton = ({ phone }) => {
  const formattedPhone = phone?.replace(/\D/g, '');
  if (!formattedPhone) return null;

  return (
    <a
      href={`https://wa.me/${formattedPhone}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center gap-3 bg-green-500 hover:bg-green-600 text-white font-semibold py-4 px-6 rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-green-500/25"
    >
      <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
        <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
      </svg>
      <span>Contáctanos por WhatsApp</span>
    </a>
  );
};

const BusinessHours = ({ hours }) => {
  const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  
  const formatTime = (time) => {
    return new Date(`2000-01-01T${time}`).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-xl hover:shadow-2xl transition-all duration-300">
      <h3 className="text-lg font-bold text-gray-100 mb-4 flex items-center gap-2">
        <Clock className="w-5 h-5 text-red-400" />
        Horario
      </h3>
      <div className="space-y-3">
        {hours?.map((hour) => (
          <div key={hour.id} className="flex justify-between items-center">
            <span className="text-gray-300 font-medium">{daysOfWeek[hour.day]}</span>
            <span className="text-gray-200 font-semibold">
              {hour.is_closed ? 'Cerrado' : `${formatTime(hour.opening_time)} - ${formatTime(hour.closing_time)}`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Gallery = ({ images }) => {
  if (!images?.length) return null;

  return (
    <div className="bg-gray-800/50 backdrop-blur-md rounded-xl p-6">
      <h3 className="text-xl font-bold text-gray-100 mb-4">Galería</h3>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div key={index} className="relative aspect-square rounded-lg overflow-hidden group">
            <img
              src={image.image_url}
              alt={`Gallery ${index + 1}`}
              className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
          </div>
        ))}
      </div>
    </div>
  );
};

const AnimatedFlame = () => (
  <div className="animate-bounce">
    <Flame className="w-6 h-6 text-red-400" />
  </div>
);

const PromotionCard = ({ promotion }) => {
  if (!promotion) return null;
  
  return (
    <div className="bg-gray-800/50 backdrop-blur-md rounded-xl p-6 hover:shadow-xl transition-all duration-300 border border-gray-700">
      <div className="flex items-start gap-4">
        <AnimatedFlame />
        <div className="flex-1">
          <h3 className="text-xl font-bold text-gray-100 mb-2">{promotion.name}</h3>
          <p className="text-gray-300 mb-4">{promotion.description}</p>
          <div className="space-y-2">
            <p className="text-gray-400 text-sm">
              Válido hasta: {new Date(promotion.end_date).toLocaleDateString()}
            </p>
            <p className="text-gray-500 text-sm">{promotion.terms_conditions}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const BusinessDetail = () => {
  const { id } = useParams();
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await fetch(`https://asobares-7f9b90b5c835.herokuapp.com/api/businesses/businesses/${id}/`);
        if (!response.ok) throw new Error('No se pudo obtener la información del negocio');
        const data = await response.json();
        setBusiness(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-xl text-gray-300">Cargando...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-xl text-red-400">Error: {error}</div>
      </div>
    );
  }

  if (!business) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-xl text-gray-300">No se encontró el negocio</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <div className="relative h-96">
        <img
          src={business.cover_photo_url}
          alt={business.name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/70 to-transparent">
          <div className="container mx-auto px-4 h-full flex items-end pb-8">
            <div className="flex items-end gap-6">
              <img
                src={business.profile_picture_url}
                alt={`${business.name} logo`}
                className="w-32 h-32 rounded-full border-4 border-gray-800 object-cover shadow-xl transform hover:scale-105 transition-transform duration-300"
              />
              <div className="mb-4">
                <h1 className="text-4xl font-bold text-white mb-2">{business.name}</h1>
                <p className="text-gray-300 text-lg">{business.category_name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-6">
            <div className="bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-xl hover:shadow-2xl transition-all duration-300">
              <h2 className="text-2xl font-bold text-gray-100 mb-4">Sobre Nosotros</h2>
              <p className="text-gray-300 leading-relaxed">{business.description}</p>
            </div>

            <div className="bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-xl hover:shadow-2xl transition-all duration-300">
              <h2 className="text-2xl font-bold text-gray-100 mb-4">Contacto</h2>
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <Phone className="w-5 h-5 text-red-400" />
                  <span className="text-gray-300">{business.phone}</span>
                </div>
                <div className="flex items-center gap-3">
                  <MapPin className="w-5 h-5 text-red-400" />
                  <span className="text-gray-300">{business.address}</span>
                </div>
              </div>
            </div>

            <WhatsAppButton phone={business.phone} />

            <div className="flex gap-4">
              <SocialLink url={business.facebook_url} icon={Facebook} platform="Facebook" />
              <SocialLink url={business.instagram_url} icon={Instagram} platform="Instagram" />
              <SocialLink url={business.twitter_url} icon={Twitter} platform="Twitter" />
              <SocialLink url={business.website_url} icon={Globe} platform="Website" />
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <BusinessHours hours={business.business_hours} />
            <Gallery images={business.gallery_images} />

            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gray-100">Promociones Activas</h2>
              {business.promotions?.map((promotion) => (
                <PromotionCard key={promotion.id} promotion={promotion} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetail;