import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircle, XCircle, AlertCircle, Clock } from 'lucide-react';
import axios from 'axios';

const CardVerification = () => {
  const { id } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('loading');
  const [cardData, setCardData] = useState(null);
  const API_URL = 'https://asobares-7f9b90b5c835.herokuapp.com';

  useEffect(() => {
    const verifyCard = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/id-cards/verify/${id}/`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }
          }
        );
        setCardData(response.data);
        setVerificationStatus(response.data.is_active ? 'valid' : 'invalid');
      } catch (error) {
        console.error('Error verificando carnet:', error);
        setVerificationStatus('error');
      }
    };

    verifyCard();
  }, [id]);

  const statusConfigs = {
    loading: {
      icon: Clock,
      title: 'Verificando carnet...',
      message: 'Por favor espere mientras verificamos el estado del carnet.',
      color: 'text-blue-500',
      bgColor: 'bg-blue-50',
      borderColor: 'border-blue-200',
    },
    valid: {
      icon: CheckCircle,
      title: 'Carnet Válido',
      message: 'Este carnet está activo y es válido.',
      color: 'text-green-500',
      bgColor: 'bg-green-50',
      borderColor: 'border-green-200',
    },
    invalid: {
      icon: XCircle,
      title: 'Carnet Inválido',
      message: 'Este carnet no está activo o ha sido suspendido.',
      color: 'text-red-500',
      bgColor: 'bg-red-50',
      borderColor: 'border-red-200',
    },
    error: {
      icon: AlertCircle,
      title: 'Error de Verificación',
      message: 'No se pudo verificar el estado del carnet. Por favor, intente nuevamente.',
      color: 'text-yellow-500',
      bgColor: 'bg-yellow-50',
      borderColor: 'border-yellow-200',
    },
  };

  const currentStatus = statusConfigs[verificationStatus];
  const StatusIcon = currentStatus.icon;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white border ${currentStatus.borderColor}`}>
          {/* Header */}
          <div className={`px-6 py-8 ${currentStatus.bgColor} text-center`}>
            <StatusIcon className={`w-16 h-16 mx-auto ${currentStatus.color}`} />
            <h2 className={`mt-4 text-2xl font-bold ${currentStatus.color}`}>
              {currentStatus.title}
            </h2>
            <p className="mt-2 text-gray-600">
              {currentStatus.message}
            </p>
          </div>

          {/* Card Details */}
          {cardData && (
            <div className="px-6 py-6 border-t border-gray-200 space-y-4">
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Detalles del Carnet
                </h3>
              </div>

              <div className="space-y-3">
                <div>
                  <p className="text-sm text-gray-500">ID del Carnet</p>
                  <p className="text-base font-medium text-gray-900">{cardData.id}</p>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Usuario</p>
                  <p className="text-base font-medium text-gray-900">{cardData.username}</p>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Fecha de Emisión</p>
                  <p className="text-base font-medium text-gray-900">
                    {formatDate(cardData.created_at)}
                  </p>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Última Actualización</p>
                  <p className="text-base font-medium text-gray-900">
                    {formatDate(cardData.updated_at)}
                  </p>
                </div>
              </div>

              <div className="mt-6">
                <div className={`inline-flex items-center px-4 py-2 rounded-full ${
                  cardData.is_active 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  <span className="text-sm font-medium">
                    {cardData.is_active ? 'Activo' : 'Inactivo'}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Footer */}
          <div className="px-6 py-4 bg-gray-50">
            <p className="text-xs text-center text-gray-500">
              Esta verificación fue realizada el {formatDate(new Date())}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardVerification;