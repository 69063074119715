import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isDark, setIsDark] = useState(true);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    
    if (storedDarkMode === null) {
      localStorage.setItem('darkMode', 'true');
      document.documentElement.classList.add('dark');
    } else {
      setIsDark(storedDarkMode === 'true');
      if (storedDarkMode === 'true') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // Determinar si está scrolleado para la animación del logo
      setIsScrolled(currentScrollY > 50);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <>
      {/* Header/Logo Container con transformación */}
      <div 
        className={`fixed w-full z-50 transition-all duration-500 ease-out
          ${isScrolled 
            ? 'top-4 left-4 w-auto h-auto' 
            : 'top-0 left-0 w-full'
          }`}
      >
        <div 
          className={`relative transition-all duration-500 ease-out
            ${isScrolled 
              ? 'w-[60px] h-[60px]' 
              : 'w-full h-[60px] bg-white/80 dark:bg-gray-800/80 backdrop-blur-md shadow-md dark:shadow-gray-900/50'
            }`}
        >
          {/* Efecto de vidrio y borde para estado scrolleado */}
          <div className={`absolute inset-0 transition-all duration-500
            ${isScrolled 
              ? 'rounded-full bg-white/10 backdrop-blur-lg border border-white/20 dark:bg-black/10 dark:border-gray-700/30 scale-110' 
              : 'rounded-none'
            }`} 
          />
          
          {/* Contenedor del logo */}
          <Link 
            to="/" 
            className={`block transition-all duration-500 h-full
              ${isScrolled 
                ? 'p-2 transform hover:scale-110' 
                : 'flex justify-center items-center'
              }`}
          >
            <img
              src="/logoFindout.png"
              alt="Logo"
              className={`dark:hidden transition-all duration-500
                ${isScrolled 
                  ? 'w-full h-full object-contain' 
                  : 'h-[40px] w-auto object-contain'
                }`}
            />
            <img
              src="/logoFindoutDarkMode.png"
              alt="Logo Dark Mode"
              className={`hidden dark:block transition-all duration-500
                ${isScrolled 
                  ? 'w-full h-full object-contain' 
                  : 'h-[40px] w-auto object-contain'
                }`}
            />
          </Link>
        </div>
      </div>

      {/* Espaciador fijo */}
      <div className="h-[60px]"></div>

      {/* Estilos adicionales para animaciones */}
      <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }

        @keyframes fadeOut {
          from { opacity: 1; transform: translateY(0); }
          to { opacity: 0; transform: translateY(-10px); }
        }

        .header-visible {
          animation: fadeIn 0.5s ease-out forwards;
        }

        .header-hidden {
          animation: fadeOut 0.5s ease-out forwards;
        }
      `}</style>
    </>
  );
};

export default Header;