import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MapPin, Clock, Phone, Tag, ExternalLink, Heart, Flame } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ImageFromS3 = ({ imageUrl, alt }) => {
  const [error, setError] = useState(false);

  if (error || !imageUrl) {
    return <img src="/api/placeholder/400/320" alt={alt} className="w-full h-48 object-cover rounded-t-xl" />;
  }

  return (
    <img
      src={imageUrl}
      alt={alt}
      className="w-full h-48 object-cover rounded-t-xl"
      onError={() => setError(true)}
    />
  );
};

const BusinessLogo = ({ logo, businessName = '', className = '' }) => {
  const [error, setError] = useState(false);

  if (error || !logo) {
    return (
      <div className={`w-16 h-16 rounded-full bg-white dark:bg-gray-800 shadow-lg 
                      flex items-center justify-center border-2 border-[#b22224] ${className}`}>
        <span className="text-2xl font-bold text-[#b22224]">
          {businessName ? businessName.charAt(0).toUpperCase() : '?'}
        </span>
      </div>
    );
  }

  return (
    <img
      src={logo}
      alt={`Logo de ${businessName || 'la empresa'}`}
      className={`w-16 h-16 rounded-full object-cover border-2 border-white shadow-lg ${className}`}
      onError={() => setError(true)}
    />
  );
};

const BusinessCard = ({ business }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const hasPromotion = business.promotions && business.promotions.length > 0;
  const currentPromotion = hasPromotion ? business.promotions[0] : null;

  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    return `${hour > 12 ? hour - 12 : hour}:${minutes} ${hour >= 12 ? 'PM' : 'AM'}`;
  };

  const isBusinessOpen = (businessHours) => {
    if (!businessHours || businessHours.length === 0) return null;
    const now = new Date();
    const currentDay = now.getDay();
    const currentHours = businessHours.find(hours => hours.day === currentDay);
    
    if (!currentHours || currentHours.is_closed) return null;
    
    const currentTime = now.toLocaleTimeString('en-US', { hour12: false });
    const isOpen = currentTime >= currentHours.opening_time && currentTime <= currentHours.closing_time;
    
    return {
      isOpen,
      openTime: formatTime(currentHours.opening_time),
      closeTime: formatTime(currentHours.closing_time)
    };
  };

  const businessStatus = isBusinessOpen(business.business_hours);

  const handleViewMore = () => {
    navigate(`/business/${business.id}`);
  };

  return (
    <motion.div
      className="relative group"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="relative overflow-hidden rounded-xl bg-white dark:bg-gray-800 shadow-lg 
                    transition-all duration-300 hover:shadow-2xl border border-gray-100 dark:border-gray-700">
        {/* Cover image and logo */}
        <div className="relative">
          <ImageFromS3 imageUrl={business.cover_photo_url} alt={`${business.name} portada`} />
          
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          
          {/* Animated logo */}
          <motion.div
            className="absolute -bottom-8 left-4"
            animate={{ y: isHovered ? -4 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <BusinessLogo 
              logo={business.profile_picture_url}
              businessName={business.name}
              className="border-4"
            />
          </motion.div>

          {/* Favorite button */}
          <motion.button
            className="absolute top-4 right-4 p-2 rounded-full bg-white/90 dark:bg-gray-800/90 shadow-lg
                     hover:bg-[#b22224] hover:text-white transition-colors duration-300"
            onClick={(e) => {
              e.preventDefault();
              setIsFavorite(!isFavorite);
            }}
            whileTap={{ scale: 0.9 }}
          >
            <Heart 
              className={`w-5 h-5 ${isFavorite ? 'fill-[#b22224] text-[#b22224]' : 'text-gray-600'}`}
            />
          </motion.button>

          {/* Status badge (Open/Closed) */}
          {businessStatus && (
            <motion.div 
              className="absolute top-4 left-4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            >
              <div className={`
                rounded-full text-sm font-bold shadow-lg backdrop-blur-md
                ${businessStatus.isOpen 
                  ? 'bg-green-500/90 text-white' 
                  : 'bg-red-500/90 text-white'}
                `}>
                <div className="flex items-center px-3 py-1">
                  <Clock className="w-4 h-4 mr-1" />
                  <span>
                    {businessStatus.isOpen 
                      ? `Abierto hasta ${businessStatus.closeTime}`
                      : `Abre ${businessStatus.openTime}`}
                  </span>
                </div>
              </div>
            </motion.div>
          )}

          {/* Promotion badge with animated flame */}
          {hasPromotion && (
            <motion.div 
              className="absolute top-16 right-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              <div className="flex items-center">
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    rotate: [-5, 5, -5]
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                  className="mr-2"
                >
                  <Flame className="w-6 h-6 text-orange-500" />
                </motion.div>
                <div className="bg-white/20 backdrop-blur-md rounded-lg px-3 py-1 border border-white/30 shadow-lg">
                  <span className="font-bold text-white">
                    {currentPromotion.discount_value}% OFF
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </div>

        {/* Content */}
        <div className="p-4 pt-10">
          <div className="space-y-1">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white">{business.name}</h3>
            
            <div className="flex items-center text-[#b22224]">
              <Tag className="w-4 h-4 mr-1" />
              <span className="text-sm font-medium">{business.category_name}</span>
            </div>

            <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
              {business.description}
            </p>

            <div className="flex items-center text-gray-500 dark:text-gray-400">
              <MapPin className="w-4 h-4 mr-1" />
              <span className="text-sm">{business.address}</span>
            </div>

            {business.phone && (
              <div className="flex items-center text-gray-500 dark:text-gray-400">
                <Phone className="w-4 h-4 mr-1" />
                <span className="text-sm">{business.phone}</span>
              </div>
            )}
          </div>
        </div>

        {/* Footer with button */}
        <div className="p-4 border-t border-gray-100 dark:border-gray-700">
          <motion.button
            className="w-full py-2 px-4 bg-[#b22224] text-white rounded-lg font-medium
                     hover:bg-[#8a1a1c] transition-colors duration-300 flex items-center justify-center"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleViewMore}
          >
            Ver más información
            <ExternalLink className="w-4 h-4 ml-2" />
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default BusinessCard;