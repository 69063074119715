import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  User, Moon, Sun, Lock, Bell, MapPin, LogOut, 
  Mail, Phone, Loader2
} from 'lucide-react';

const Settings = () => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [location, setLocation] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Load user data from localStorage
    const storedUserData = localStorage.getItem('user');
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setUserData(parsedData);
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    } else {
      navigate('/login');
    }

    // Load dark mode from localStorage
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    }
  }, [navigate]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
    localStorage.setItem('darkMode', !darkMode);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  if (!userData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-red-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-red-700 to-red-900 dark:from-gray-900 dark:to-black p-4 transition-colors duration-300">
      <div className="max-w-2xl mx-auto space-y-6">
        {/* Profile Section */}
        <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-6 shadow-xl">
          <div className="flex items-center space-x-4 mb-6">
            <div className="relative">
              <div className="w-20 h-20 rounded-full overflow-hidden">
                <img 
                  src={userData.profile_photo_url || '/placeholder-avatar.png'} 
                  alt={userData.first_name}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-white">
                {userData.first_name} {userData.last_name}
              </h1>
              <p className="text-red-200">Información de perfil</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="space-y-2">
              <label className="text-red-200 text-sm">Nombre</label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                  {userData.first_name}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Apellido</label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                  {userData.last_name}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Correo electrónico</label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                  {userData.email}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Teléfono</label>
              <div className="relative">
                <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                  {userData.phone_number || 'No especificado'}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Dirección</label>
              <div className="relative">
                <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                  {userData.address || 'No especificada'}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Settings Options */}
        <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-6 shadow-xl space-y-4">
          <div 
            onClick={toggleDarkMode}
            className="flex items-center justify-between p-4 bg-black/20 rounded-xl cursor-pointer hover:bg-black/30 transition-colors"
          >
            <div className="flex items-center space-x-3">
              {darkMode ? (
                <Moon className="w-5 h-5 text-red-200" />
              ) : (
                <Sun className="w-5 h-5 text-red-200" />
              )}
              <span className="text-white">Modo oscuro</span>
            </div>
            <div className={`w-12 h-6 rounded-full p-1 transition-colors ${darkMode ? 'bg-red-500' : 'bg-gray-600'}`}>
              <div className={`w-4 h-4 rounded-full bg-white transition-transform ${darkMode ? 'translate-x-6' : 'translate-x-0'}`} />
            </div>
          </div>

          <div className="flex items-center justify-between p-4 bg-black/20 rounded-xl cursor-pointer hover:bg-black/30 transition-colors">
            <div className="flex items-center space-x-3">
              <Bell className="w-5 h-5 text-red-200" />
              <span className="text-white">Notificaciones</span>
            </div>
            <div className={`w-12 h-6 rounded-full p-1 transition-colors ${notifications ? 'bg-red-500' : 'bg-gray-600'}`}>
              <div className={`w-4 h-4 rounded-full bg-white transition-transform ${notifications ? 'translate-x-6' : 'translate-x-0'}`} />
            </div>
          </div>

          <div className="flex items-center justify-between p-4 bg-black/20 rounded-xl cursor-pointer hover:bg-black/30 transition-colors">
            <div className="flex items-center space-x-3">
              <MapPin className="w-5 h-5 text-red-200" />
              <span className="text-white">Ubicación</span>
            </div>
            <div className={`w-12 h-6 rounded-full p-1 transition-colors ${location ? 'bg-red-500' : 'bg-gray-600'}`}>
              <div className={`w-4 h-4 rounded-full bg-white transition-transform ${location ? 'translate-x-6' : 'translate-x-0'}`} />
            </div>
          </div>

          <button
            onClick={handleLogout}
            className="w-full flex items-center justify-center space-x-2 p-4 bg-red-500 hover:bg-red-600 rounded-xl text-white transition-colors"
          >
            <LogOut className="w-5 h-5" />
            <span>Cerrar sesión</span>
          </button>
        </div>

        {/* Version Information */}
        <div className="text-center text-white/60 text-sm">
          <p>Version 1.0.0</p>
        </div>
      </div>
    </div>
  );
};

export default Settings;