import React, { useState, useRef, useEffect } from 'react';
import { ArrowLeft, Clock } from 'lucide-react';
import { BsSend } from 'react-icons/bs';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastActivity, setLastActivity] = useState(null);
  const messagesEndRef = useRef(null);

  // Configuración de variables de entorno para Create React App
  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const OPENAI_MODEL = process.env.REACT_APP_OPENAI_MODEL || 'gpt-3.5-turbo';
  const SYSTEM_PROMPT = process.env.REACT_APP_SYSTEM_PROMPT || 'Eres un asistente útil y amigable.';
  const MAX_TOKENS = parseInt(process.env.REACT_APP_MAX_TOKENS || '150');
  const TEMPERATURE = parseFloat(process.env.REACT_APP_TEMPERATURE || '0.7');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const savedLastActivity = localStorage.getItem('lastActivity');
    if (savedLastActivity) {
      setLastActivity(new Date(savedLastActivity));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const currentTime = new Date();
    setLastActivity(currentTime);
    localStorage.setItem('lastActivity', currentTime.toISOString());

    const userMessage = {
      role: 'user',
      content: input
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    try {
      if (!OPENAI_API_KEY) {
        throw new Error('API key no configurada');
      }

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: OPENAI_MODEL,
          messages: [
            {
              role: 'system',
              content: SYSTEM_PROMPT
            },
            ...messages,
            userMessage
          ],
          max_tokens: MAX_TOKENS,
          temperature: TEMPERATURE,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || 'Error en la solicitud a OpenAI');
      }

      const data = await response.json();
      setMessages(prev => [...prev, data.choices[0].message]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: `Error: ${error.message || 'Ha ocurrido un error. Por favor, intenta de nuevo.'}`
      }]);
    } finally {
      setLoading(false);
    }
  };

  const formatLastActivity = (date) => {
    if (!date) return 'Sin actividad';
    
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    
    return date.toLocaleDateString('es-ES', options).replace(',', ' -');
  };

  const MessageBubble = ({ message, isUser }) => (
    <div
      className={`flex items-start gap-3 ${
        isUser ? 'flex-row-reverse' : ''
      }`}
    >
      {isUser && (
        <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#8a1b1d]">
          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
        </div>
      )}
      <div 
        className={`max-w-[80%] p-4 rounded-2xl ${
          isUser
            ? 'bg-[#8a1b1d] text-white rounded-tr-none'
            : 'bg-gray-200 dark:bg-gray-700 dark:text-white rounded-tl-none'
        }`}
      >
        <p className="text-sm md:text-base leading-relaxed whitespace-pre-wrap">
          {message.content}
        </p>
      </div>
    </div>
  );

  return (
    <div className="h-screen flex flex-col bg-white dark:bg-gray-900 transition-colors duration-200">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-10 bg-white dark:bg-gray-900 border-b dark:border-gray-800 shadow-sm">
        <div className="max-w-4xl mx-auto p-4">
          <div className="flex items-center gap-4">
            <button
              onClick={() => window.history.back()}
              className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-800 
                       hover:bg-gray-200 dark:hover:bg-gray-700 transition-all duration-200"
              aria-label="Volver atrás"
            >
              <ArrowLeft className="w-5 h-5 text-gray-600 dark:text-gray-300" />
            </button>
            <img 
                  src="/logo512.png" 
                  alt="Asobares Logo" 
                  className="h-12 w-auto"
                />
            <div className="flex flex-col">
           
              <div className="flex items-center">
                
                <h1 className="text-lg font-semibold text-gray-800 dark:text-white">
                  Asobares Chatbot
                </h1>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400 ">
                <Clock className="w-4 h-4" />
                {formatLastActivity(lastActivity)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto p-4 space-y-6 mt-24 mb-20">
        {messages.map((message, index) => (
          <MessageBubble
            key={index}
            message={message}
            isUser={message.role === 'user'}
          />
        ))}
        {loading && (
          <div className="flex items-center gap-2 ml-14">
            <div className="w-3 h-3 rounded-full bg-[#8a1b1d] animate-bounce" />
            <div className="w-3 h-3 rounded-full bg-[#8a1b1d] animate-bounce delay-100" />
            <div className="w-3 h-3 rounded-full bg-[#8a1b1d] animate-bounce delay-200" />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Form */}
      <div className="fixed bottom-0 left-0 right-0 border-t dark:border-gray-800 bg-white dark:bg-gray-900 p-4">
        <form onSubmit={handleSubmit} className="max-w-4xl mx-auto">
          <div className="flex gap-2 items-center">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Escribe tu mensaje..."
              className="flex-1 p-4 rounded-xl border dark:border-gray-700 bg-white dark:bg-gray-800 
                       text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400
                       focus:outline-none focus:ring-2 focus:ring-[#8a1b1d] dark:focus:ring-[#8a1b1d]
                       text-sm md:text-base transition-all duration-200"
              disabled={loading}
            />
            <button
              type="submit"
              disabled={loading}
              className="p-4 bg-[#8a1b1d] text-white rounded-xl transition-all duration-200 
                       disabled:opacity-50 hover:bg-opacity-90 active:scale-95 flex items-center justify-center"
              aria-label="Enviar mensaje"
            >
              <BsSend className="text-lg" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatBot;