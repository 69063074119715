import React, { useState, useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  CreditCard, 
  RotateCw, 
  X, 
  ChevronsUpDown,
  RefreshCw,
  ChevronLeft
} from 'lucide-react';

const IDCardVerifier = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scannerActive, setScannerActive] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const [manualId, setManualId] = useState('');
  const [error, setError] = useState(null);
  const [cameraPermission, setCameraPermission] = useState(null);
  const [scanner, setScanner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showControls, setShowControls] = useState(true);

  const clearScanner = () => {
    if (scanner) {
      scanner.clear().catch(error => {
        console.error('Error clearing scanner:', error);
      });
      setScanner(null);
    }
  };

  useEffect(() => {
    const requestCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraPermission(true);
        stream.getTracks().forEach(track => track.stop());
      } catch (err) {
        setCameraPermission(false);
        setError('Camera access required');
      }
    };
    requestCameraPermission();

    return () => clearScanner();
  }, []);

  useEffect(() => {
    if (scannerActive && cameraPermission) {
      clearScanner();

      const config = {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        aspectRatio: 1,
        facingMode: isFrontCamera ? "user" : "environment",
      };

      const newScanner = new Html5QrcodeScanner('reader', config, false);
      setScanner(newScanner);

      newScanner.render(onScanSuccess, onScanError);
    }

    return () => clearScanner();
  }, [scannerActive, isFrontCamera]);

  const onScanSuccess = async (decodedText) => {
    try {
      const id = decodedText.trim();
      setIsLoading(true);
      const isInApp = location.pathname.startsWith('/app');
      const baseUrl = isInApp ? '/app' : '';
      navigate(`${baseUrl}/verify-card/${id}`);
    } catch (error) {
      setError('Invalid QR code format');
      setTimeout(() => setError(null), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const onScanError = (error) => {
    console.warn(`Scan error: ${error}`);
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    if (manualId.trim()) {
      const isInApp = location.pathname.startsWith('/app');
      const baseUrl = isInApp ? '/app' : '';
      navigate(`${baseUrl}/verify-card/${manualId.trim()}`);
    }
  };

  const toggleCamera = () => {
    clearScanner();
    setIsFrontCamera(!isFrontCamera);
    setScannerActive(false);
    setTimeout(() => setScannerActive(true), 100);
  };

  const restartScanner = () => {
    clearScanner();
    setScannerActive(false);
    setTimeout(() => setScannerActive(true), 100);
  };

  return (
    <div className="w-full h-screen bg-gray-50 relative overflow-hidden">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-white shadow-sm p-4 flex items-center justify-between">
        <button 
          onClick={() => navigate(-1)}
          className="p-2 hover:bg-gray-100 rounded-full transition-all"
        >
          <ChevronLeft className="w-6 h-6 text-gray-700" />
        </button>
        <span className="text-lg font-medium text-gray-900">QR Scanner</span>
        <button 
          onClick={() => setShowControls(!showControls)}
          className="p-2 hover:bg-gray-100 rounded-full transition-all"
        >
          <ChevronsUpDown className="w-6 h-6 text-gray-700" />
        </button>
      </div>

      {/* Main Content */}
      <div className="absolute inset-0 pt-16">
        {/* Non-scanning state */}
        {!scannerActive && (
          <div className="h-full p-4">
            {/* Manual Input */}
            <div className="mb-8 space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <form onSubmit={handleManualSubmit} className="space-y-3">
                  <input
                    type="text"
                    value={manualId}
                    onChange={(e) => setManualId(e.target.value)}
                    placeholder="Enter Card ID manually"
                    className="w-full p-3 border border-gray-200 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#ce3527]"
                  />
                  <button
                    type="submit"
                    className="w-full bg-[#ce3527] text-white py-3 rounded-lg font-medium hover:bg-[#b32d22] transition-all"
                  >
                    Verify
                  </button>
                </form>
              </div>
            </div>

            {/* Scanner Start Button */}
            <div className="flex flex-col items-center space-y-4">
              <button
                onClick={() => {
                  if (cameraPermission) {
                    setScannerActive(true);
                  } else {
                    setError('Please allow camera access');
                    setTimeout(() => setError(null), 3000);
                  }
                }}
                className="p-6 bg-white rounded-full shadow-lg hover:shadow-xl transition-all group"
              >
                <CreditCard className="w-8 h-8 text-[#ce3527] group-hover:scale-110 transition-transform" />
              </button>
              <p className="text-gray-600 text-center">
                Tap to start scanning
              </p>
            </div>
          </div>
        )}

        {/* Scanning state */}
        {scannerActive && (
          <div className="relative h-full">
            {/* Scanner Container */}
            <div id="reader" className="absolute inset-0">
              <style>{`
                #reader {
                  width: 100vw !important;
                  height: 100vh !important;
                  padding: 0 !important;
                  border: none !important;
                }
                #reader video {
                  width: 100vw !important;
                  height: 100vh !important;
                  object-fit: cover !important;
                }
                #reader__scan_region {
                  width: 100% !important;
                  height: 100% !important;
                  background: transparent !important;
                }
                #reader__dashboard {
                  display: none !important;
                }
              `}</style>
            </div>

            {/* Scanning Frame */}
            <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
              <div className="w-64 h-64 backdrop-blur-sm bg-black/5 border-2 border-[#ce3527]/30 rounded-3xl relative overflow-hidden">
                {/* Corner Indicators */}
                <div className="absolute top-0 left-0 w-6 h-6 border-t-2 border-l-2 border-[#ce3527] rounded-tl-lg" />
                <div className="absolute top-0 right-0 w-6 h-6 border-t-2 border-r-2 border-[#ce3527] rounded-tr-lg" />
                <div className="absolute bottom-0 left-0 w-6 h-6 border-b-2 border-l-2 border-[#ce3527] rounded-bl-lg" />
                <div className="absolute bottom-0 right-0 w-6 h-6 border-b-2 border-r-2 border-[#ce3527] rounded-br-lg" />
                
                {/* Scan Animation */}
                <div className="absolute top-0 left-0 right-0 h-1 bg-[#ce3527] animate-[scan_2s_ease-in-out_infinite]" />
              </div>
            </div>

            {/* Controls Panel */}
            {showControls && (
              <div className="absolute bottom-0 left-0 right-0 bg-white shadow-lg transition-all">
                <div className="flex justify-around items-center p-4">
                  <button
                    onClick={() => setScannerActive(false)}
                    className="p-3 bg-gray-100 rounded-full hover:bg-gray-200 transition-all"
                  >
                    <X className="w-6 h-6 text-[#ce3527]" />
                  </button>
                  <button
                    onClick={toggleCamera}
                    className="p-3 bg-gray-100 rounded-full hover:bg-gray-200 transition-all"
                  >
                    <RotateCw className="w-6 h-6 text-[#ce3527]" />
                  </button>
                  <button
                    onClick={restartScanner}
                    className="p-3 bg-gray-100 rounded-full hover:bg-gray-200 transition-all"
                  >
                    <RefreshCw className="w-6 h-6 text-[#ce3527]" />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Error Messages */}
        {error && (
          <div className="fixed top-20 inset-x-4 bg-[#ce3527] text-white p-4 rounded-lg text-center shadow-lg z-50">
            {error}
          </div>
        )}

        {/* Loading Overlay */}
        {isLoading && (
          <div className="fixed inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#ce3527] border-t-transparent" />
          </div>
        )}
      </div>

      {/* Global styles */}
      <style jsx>{`
        @keyframes scan {
          0% { transform: translateY(0); opacity: 0; }
          50% { opacity: 1; }
          100% { transform: translateY(256px); opacity: 0; }
        }
      `}</style>
    </div>
  );
};

export default IDCardVerifier;