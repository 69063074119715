import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MessageCircle, Building2, CreditCard, QrCode, ScanLine, Settings } from 'lucide-react';

const MenuBar = () => {
  const location = useLocation();
  const [isIconSwapped, setIsIconSwapped] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setIsIconSwapped(prev => !prev);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="fixed bottom-0 w-full h-14 bg-white dark:bg-gray-900 flex justify-center items-center shadow-lg dark:shadow-gray-900/50 backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90 z-10">
      <nav className="w-full max-w-lg mx-auto px-6">
        <ul className="flex items-center justify-between">
          {/* Chatbots */}
          <li>
            <Link 
              to="/chatbots"
              className="flex flex-col items-center justify-center p-2 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <MessageCircle 
                size={24} 
                className={`text-gray-700 dark:text-gray-300 ${location.pathname.includes('/chatbots') ? 'text-primary dark:text-primary-dark' : ''}`}
              />
            </Link>
          </li>

          {/* Empresas */}
          <li>
            <Link 
              to="/search" 
              className="flex flex-col items-center justify-center p-2 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <Building2 
                size={24} 
                className={`text-gray-700 dark:text-gray-300 ${location.pathname === '/search' ? 'text-primary dark:text-primary-dark' : ''}`}
              />
            </Link>
          </li>

          {/* Carnet with animated icon */}
          <li className="relative -mt-[56px]">
            <Link 
              to="/" 
              className="flex items-center justify-center w-16 h-16 rounded-full bg-primary dark:bg-primary-dark shadow-lg transform transition-transform hover:scale-105"
            >
              <div className="relative w-6 h-6">
                <CreditCard
                  className={`absolute inset-0 text-white transform transition-all duration-500 ease-in-out
                    ${isIconSwapped ? 'opacity-0 rotate-180 scale-0' : 'opacity-100 rotate-0 scale-100'}`}
                />
                <QrCode
                  className={`absolute inset-0 text-white transform transition-all duration-500 ease-in-out
                    ${isIconSwapped ? 'opacity-100 rotate-0 scale-100' : 'opacity-0 -rotate-180 scale-0'}`}
                />
              </div>
            </Link>
          </li>

          {/* Scan QR */}
          <li>
            <Link 
              to="/QRscan" 
              className="flex flex-col items-center justify-center p-2 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <ScanLine 
                size={24} 
                className={`text-gray-700 dark:text-gray-300 ${location.pathname === '/QRscan' ? 'text-primary dark:text-primary-dark' : ''}`}
              />
            </Link>
          </li>

          {/* Ajustes */}
          <li>
            <Link 
              to="/settings" 
              className="flex flex-col items-center justify-center p-2 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <Settings 
                size={24} 
                className={`text-gray-700 dark:text-gray-300 ${location.pathname === '/settings' ? 'text-primary dark:text-primary-dark' : ''}`}
              />
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default MenuBar;