import React, { useState, useEffect } from 'react';
import { Share2, Copy, Download, Check, X, CreditCard, Printer } from 'lucide-react';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

const UserIDCard = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem('user');
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setUserData(parsedData);
      } catch (error) {
        console.error('Error parsing user data:', error);
        setError('Error al cargar datos del usuario');
      }
    } else {
      setError('No se encontró información del usuario');
    }
  }, []);

  const getPublicVerificationUrl = (cardId) => {
    return `${window.location.origin}/verify-card/${cardId}`;
  };

  const handleShare = async () => {
    const shareUrl = getPublicVerificationUrl(userData?.id_card?.id);
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Verificar carnet de ${userData?.first_name} ${userData?.last_name}`,
          url: shareUrl,
        });
      } else {
        await navigator.clipboard.writeText(shareUrl);
        showSuccessMessage('¡URL copiada!');
      }
    } catch (error) {
      console.error('Error al compartir:', error);
      showSuccessMessage('Error al compartir', true);
    }
  };

  const handleCopyId = async () => {
    try {
      await navigator.clipboard.writeText(userData?.id_card?.id);
      showSuccessMessage('¡ID copiado!');
    } catch (error) {
      console.error('Error al copiar:', error);
      showSuccessMessage('Error al copiar', true);
    }
  };

  const showSuccessMessage = (message, isError = false) => {
    setShowSuccess({ message, isError });
    setTimeout(() => setShowSuccess(null), 2000);
  };

  const handleDownload = async () => {
    if (isDownloading) return;
    
    setIsDownloading(true);
    const cardElement = document.querySelector('.id-card');
    
    try {
      if (cardElement) {
        const canvas = await html2canvas(cardElement, {
          scale: 2,
          backgroundColor: null,
          logging: false,
        });
        
        const printCanvas = document.createElement('canvas');
        printCanvas.width = canvas.width;
        printCanvas.height = canvas.height;
        const ctx = printCanvas.getContext('2d');
        
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(canvas, 0, 0);
        
        const link = document.createElement('a');
        link.download = `carnet-${userData.identification_number}.png`;
        link.href = printCanvas.toDataURL('image/png');
        link.click();
        showSuccessMessage('¡Carnet descargado!');
      }
    } catch (error) {
      console.error('Error al descargar:', error);
      showSuccessMessage('Error al descargar', true);
    } finally {
      setIsDownloading(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  if (error) {
    return (
      <div className="min-h-screen relative w-full h-full overflow-hidden bg-slate-900 flex items-center justify-center">
        <div className="absolute inset-0 bg-dot-pattern opacity-5"></div>
        <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-8 text-center border border-white/20">
          <p className="text-red-200 mb-4">{error}</p>
        </div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="min-h-screen bg-slate-900 flex items-center justify-center p-4">
        <div className="absolute inset-0 bg-dot-pattern opacity-5"></div>
        <p className="text-white">Cargando información...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-slate-900 flex items-center justify-center p-3 py-6 relative">
      {/* Modern Background Pattern using Tailwind's built-in pattern */}
      <div className="absolute inset-0 bg-[radial-gradient(#ffffff33_1px,transparent_1px)] [background-size:20px_20px] opacity-5"></div>
      <div className="absolute inset-0 bg-gradient-to-br from-[#b22224]/20 via-slate-900 to-slate-900"></div>

      <div className="max-w-md w-full relative z-10">
        <div className="id-card relative bg-white/10 backdrop-blur-xl rounded-3xl shadow-2xl border border-white/10 overflow-hidden">
          {/* Header Section with Modern Pattern */}
          <div className="relative h-40 bg-gradient-to-r from-[#b22224] to-[#8a1a1c]">
            <div className="absolute inset-0 [background-image:radial-gradient(circle_at_1px_1px,rgba(255,255,255,0.1)_2px,transparent_0)] [background-size:20px_20px]"></div>
            <div className="absolute -bottom-16 left-1/2 transform -translate-x-1/2">
              {userData.profile_photo_url ? (
                <img
                  src={userData.profile_photo_url}
                  alt={`${userData.first_name} ${userData.last_name}`}
                  className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-xl transition-transform hover:scale-105"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-gradient-to-br from-[#b22224] to-[#8a1a1c] flex items-center justify-center border-4 border-white shadow-xl transition-transform hover:scale-105">
                  <span className="text-4xl font-bold text-white">
                    {userData.first_name.charAt(0).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Content Grid */}
          <div className="p-6 pt-20 grid gap-4 pb-10">
            {/* Name and Status */}
            <div className="text-center ">
              <h1 className="text-xl font-bold text-white leading-6">
                {`${userData.first_name} ${userData.last_name}`}
              </h1>
              <div className="flex items-center justify-center gap-2">
                <CreditCard className="w-4 h-4 text-red-300" />
                <p className="text-red-300 font-mono">{userData.identification_number}</p>
              </div>
              
            </div>

            {/* QR Code Box */}
            <div className="bg-white/5 p-6 rounded-2xl border border-white/10 transition-transform hover:scale-102">
              <div className="bg-white p-4 rounded-xl mb-4">
                <QRCode 
                  value={getPublicVerificationUrl(userData.id_card.id)} 
                  size={200}
                  level="H"
                  className="w-full h-auto"
                />
              </div>
            </div>

            {/* ID Number Box */}
            <div className="bg-white/5 p-4 rounded-2xl border border-white/10 transition-transform hover:scale-102">
              <p className="text-red-300 text-sm mb-1">ID del Carnet</p>
              <p className="text-white font-mono break-all text-sm">{userData.id_card.id}</p>
            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-4 gap-3 mt-4">
              {[
                { label: 'Compartir', icon: Share2, handler: handleShare },
                { label: 'Copiar', icon: Copy, handler: handleCopyId },
                { label: 'Descargar', icon: Download, handler: handleDownload },
                { label: 'Imprimir', icon: Printer, handler: handlePrint }
              ].map(({ label, icon: Icon, handler }) => (
                <button
                  key={label}
                  className="flex flex-col items-center justify-center p-3 rounded-xl bg-white/5 border border-white/10 hover:bg-white/10 transition-all hover:scale-105 active:scale-95"
                  onClick={handler}
                  disabled={label === 'Descargar' && isDownloading}
                >
                  <Icon className="w-5 h-5 text-red-300 mb-1" />
                  <span className="text-xs text-red-300">
                    {label === 'Descargar' && isDownloading ? 'Descargando...' : label}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Success Message */}
        {showSuccess && (
          <div className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 ${
            showSuccess.isError ? 'bg-red-500' : 'bg-green-500'
          } text-white px-4 py-2 rounded-full shadow-lg transition-opacity duration-200`}>
            {showSuccess.message}
          </div>
        )}
      </div>

      {/* Print Styles */}
      <style jsx global>{`
        @media print {
          body * {
            visibility: hidden;
          }
          .id-card, .id-card * {
            visibility: visible;
          }
          .id-card {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 85.6mm;
            height: 53.98mm;
          }
        }
      `}</style>
    </div>
  );
};

export default UserIDCard;